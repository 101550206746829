<template>
  <div class="rule-warpper">
    <div class="mb10">输入样式：{{ getStyleName() }}</div>

    <div class="mb10">是否默认系统数据: {{ data.isSetDefaultCode == CAN ? '是' : '否' }}</div>
    <div class="mb10">是否可插入变量: {{ canInsert ? '是' : '否' }}</div>

    <template v-if="canInsert">
      可插入变量: <span class="blue">{{ validVariable }}</span>
    </template>
    <template v-else>
      默认指向变量: <span class="blue">{{ data.customfieldName }}</span>
    </template>
  </div>
</template>

<script>
import { TYPE_ALIEXPRESS_NUM, TYPE_AMAZON_NUM, TYPE_OTHER_NUM } from '@/utils/constant/fieldConst'
export default {
  props: {
    data: Object,
    curTabItem: Object
  },

  data() {
    return {
      CAN: 1
    }
  },

  computed: {
    canInsert({ data, CAN }) {
      return data.isAllowSystemCode == CAN
    },

    validVariable({ data }) {
      const code = this.curTabItem?.value
      const mappingList = {
      [TYPE_ALIEXPRESS_NUM] : $GET(data, 'customfieldIdListName', []).join('、'),
      [TYPE_AMAZON_NUM] : $GET(data, 'customfieldNameList', []).join('、')
    }
      return mappingList[code]
    }
  },

  methods: {
    getStyleName() {
      const code = this.curTabItem?.value
      return {
        [TYPE_AMAZON_NUM]: {
          1: '单文本框',
          2: '下拉',
          3: '图像',
          4: '多文本框'
        },
        [TYPE_ALIEXPRESS_NUM]:{
          1: '文本框',
          2: '下拉',
          3: '图像',
          4: '数字输入框'
        },
        [TYPE_OTHER_NUM]:{}
      }[code][this.data.fieldType]
    }
  }
}
</script>

<style lang="scss" scoped>
.blue {
  color: $--color-primary;
}
</style>
